jQuery(document).ready(function ($) {
  var robotCheck = 0;

  $('#email').on('input change focus', function () {
    robotCheck = 3956;
  });
  $('#contact_form')
    // to prevent form from submitting upon successful validation
    .on('submit', function (ev) {
      ev.preventDefault();
      $('body').css('cursor', 'wait');
      $('#error-message').text('');
      $('#contact_form button .text').hide();
      $('#contact_form button #loading').show();
      $('#contact_form button').attr('disabled', 'true');
      //set cursor to wait
      $('body').css('cursor', 'wait');
      var that = $('#contact_form'),
        url = that.attr('action'),
        method = that.attr('method'),
        data = {};

      that.find('[name]').each(function () {
        var that = $(this),
          name = that.attr('name'),
          value = that.val();
        data[name] = value;
      });

      if (robotCheck === 3956) {
        $.ajax({
          url: url,
          type: method,
          data: data,
          success: function (response) {
            response = JSON.parse(response);
            console.log(response);
            if (response === 'success') {
              $('#error-message').hide();
              //replace #main with the html of response.prize_html
              $('#result-container').html(
                '<p class="text-2xl text-white text-center">Thank you for your request. We will contact you as soon as possible.</p>'
              );
            }
            $('#contact_form button .text').show();
            $('#contact_form button #loading').hide();
            $('#contact_form button').removeAttr('disabled');
            $('body').css('cursor', 'default');
          },
          error: function (response) {
            $('#contact_form button .text').show();
            $('#contact_form button #loading').hide();
            $('#contact_form button').removeAttr('disabled');
            $('#error-message').text('Failed, try again.');
            console.log(response);
          },
        });
      } else {
        $('#error-message').text('Please fill in your email manually.');
        $('#contact_form button #loading').hide();
        $('#contact_form button .text').show();
        $('#contact_form button').removeAttr('disabled');
        $('body').css('cursor', 'default');
      }
    });
});
